.btnCarousel{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
    color: #FFFFFF;
    background: rgba(0, 0, 0, 0.5);
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 100px;
    padding-left: 30px;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 15px;
    transition: all 0.3s ease-out 0s;

}
.btnCarousel:hover svg{
    margin-left: 10px;
    margin-right: 0px;
}
.btnCarousel:hover{
    padding-right: 20px;
    color: #000000;
    background: #FFFFFF;
}
.btnCarousel svg{
    transition: all 0.3s ease-out 0s;
    margin-right: 30px;
}
.btnCarousel svg path{
    transition: all 0.3s ease-out 0s;
    margin-right: 30px;
}
.btnCarousel:hover svg path{
    stroke: #000000;
}