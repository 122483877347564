.mainContainer{
    background-image: url('../../img/bg.png');
    background-size: cover;
    animation: bganim 40s ease-in-out reverse infinite;
}

@keyframes bganim{
    0%{
        background-position-x: 0px;
    }
    100%{
        background-position-x: 1500px;
    }
}